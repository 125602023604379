<template>
    <div class="menu-body"
        v-if="$oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con" style="padding: 0">
            <div class="even-space-vertical-align">
                <div class="mat-info-con main-tab-act default-font-color" style="font-family: 'BarlowB', sans-serif">
                    The machine groups defined on this page will remain applicable for whole year unless modified in
                    future months.
                </div>
            </div>
        </div>
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="plantId" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId" disabled
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                {{ plant.plantName + ", " + plant.regionCode }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="padding-left: 2rem">
                <b-form-checkbox id="moveList" v-model="moveListValue" name="moveListValue" value="moveNextYear"
                    unchecked-value="not_moveNextYear" @change="getConstraintDtlsForNextYear()"
                    :checked="shouldMovePrev">
                    <span class="btn ed-st-btn constraint-heading-lbl"
                        style="border: transparent; background: transparent; color: #274ba7" pill
                        variant="outline-primary" v-b-modal.copyPrevConsPopup>
                        Move list from previous year to the current year
                    </span>
                </b-form-checkbox>
            </div>
            <!-- loop starts from here -->
            <div class="stacked-elements" v-for="(group, noOfGroup) in groupList" :key="noOfGroup">
                <div class="plant-dd" style="padding: 1rem 0">
                    <label for="workcenterId" class="form-label select-lable lable-text"> Work Center </label>
                    <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                        v-model="group.workCenterSelected" @change="getMachinesForList(noOfGroup)">
                        <option v-for="wc1 in workCenterList" v-bind:key="wc1.workcenterId" :value="wc1.workcenterId">
                            {{ wc1.workcenterName }}
                        </option>
                    </select>
                </div>
                <div class="card-main-tl">Group {{ noOfGroup + 1 }}</div>
                <div class="card-tab-act" style="opacity: 0.5; padding: 0.8rem 0">
                    Select Machines<span class="require-cl">*</span>
                </div>

                <div class="col-md-12 no-padding even-space-vertical-align">
                    <div class="col-md-8 border-box scroll" v-if="group.machineList.length > 1">
                        <div v-for="ml1 in group.machineList" :key="ml1.machineId"
                            class="individual-checkbox machine-list">
                            <div class="chk-container">
                                <b-form-checkbox type="checkbox" :disabled="ml1.machineAlreadyMarked"
                                    :checked="ml1.machineChecked" name="executive" class="radio"
                                    @change="onMachineCheck($event, noOfGroup, group, ml1)">
                                    {{ ml1.machineName }}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 border-box scroll no-data-block" v-else>
                        <img alt="no data" src="@/assets/No_Data.svg" />
                        <div class="ed-date">No Data Found</div>
                    </div>
                    <div class="col-md-4 alter-of-border-box">
                        <div class="plant-card-constraint">
                            <div class="info-card-lable">Number of Constrained Machines Selected</div>
                            <div class="info-card-value">
                                {{ group.noOfMachinesforGrp }}
                            </div>
                        </div>
                        <div style="width: 22rem">
                            <label for="constName" class="form-label select-lable lable-text"> Constrained WC Name<span
                                    class="require-cl">*</span> </label>
                            <b-form-input type="text" v-model="group.constraintGrpName" id="constName" maxlength="30"
                                class="form-control select-text select-text-pd" />
                        </div>
                        <div style="width: 22rem">
                            <label for="constraintTypeId" class="form-label select-lable lable-text"> Type of
                                Constraint<span class="require-cl">*</span> </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="constraintTypeId"
                                v-model="group.constraintTypeId">
                                <option disabled value="-1">Select</option>
                                <option v-for="ct in constraintType" v-bind:key="ct.constraintTypeId"
                                    :disabled="ct.alreadyExist" :value="ct.constraintTypeId">
                                    {{ ct.constraintType }}
                                </option>
                            </select>
                        </div>
                        <div style="width: 22rem; display: flex; align-items: center; justify-content: space-between">
                            <label for="isPrevConstraint" style="width: 70%; font: 1.1rem 'BarlowM', sans-serif"> Is
                                Previous Constraint ? </label>
                            <select class="form-control select-text select-text-pd cust-st-icon" id="isPrevConstraint"
                                style="width: 25%" v-model="group.isPrevConstraint">
                                <option key="Y" value="Y">Yes</option>
                                <option key="N" value="N">No</option>
                            </select>
                        </div>
                        <div class="button-div" v-if="noOfGroup === groupList.length - 1">
                            <b-button class="btn ed-st-btn"
                                style="border: transparent; background: transparent; color: #274ba7" pill
                                variant="outline-primary" @click="$router.go(-1)">
                                Cancel
                            </b-button>
                            <b-button v-if="noOfGroup < maxGroupCount - 1" class="asmt-bt button-sm"
                                style="padding: 0.25rem 1rem; margin-left: 1rem" pill variant="outline-primary"
                                :disabled="showsaveandlistbtn" @click="addNewGroup()">
                                Add New List
                            </b-button>
                            <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                                :disabled="showsaveandlistbtn" class="btn cancel-save-btn button-sm"
                                @click="saveManualData()">
                                Save
                            </b-button>
                        </div>
                        <div class="button-div" v-else></div>
                    </div>
                </div>
            </div>
            <!-- loop ends here -->
        </div>

        <!-- prev constraint popup -->
        <b-modal id="moveListValue" size="lg" ref="myModal"
            title="Are you sure you want to move the List from the current year to the next year ?"
            :visible="moveListValue == 'moveNextYear'" hide-footer @hide="moveListValue = 'not_moveNextYear'">
            <div>
                <b-table-simple responsive outlined id="constraint-grouping" sticky-header
                    class="machine-action-table scroll gn-table-b">
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th v-for="header in constraintHeaders" :key="header.key"> {{ header.label }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody v-if="constraintHeadersDtl && constraintHeadersDtl.length !== 0">
                        <b-tr v-for="(rowData, index) in constraintHeadersDtl" :key="index">
                            <b-td class="machine-action-table-machine">
                                {{ rowData.constraintGroupName }}
                            </b-td>
                            <b-td class="machine-action-table-machine">
                                {{ rowData.workcenterName }}
                            </b-td>
                            <b-td class="machine-action-table-machine">
                                {{ rowData.totalMachines }}
                            </b-td>
                            <b-td class="machine-action-table-machine">
                                {{ rowData.contstaintType }}
                            </b-td>
                            <b-td class="machine-action-table-machine">
                                <b-form-checkbox type="checkbox" :checked="rowData.isChecked" name="moveNxtYr"
                                    @change="prevYearCheckBox($event, index)" class="radio">
                                    <label style="padding: 0.6rem">{{ rowData.MoveDatatoNextYear }}</label>
                                </b-form-checkbox>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div class="button-div" style="justify-content: right; width: 100%">
                <b-button class="btn ed-st-btn" style="border: transparent; background: transparent; color: #274ba7"
                    pill variant="outline-primary" @click="closePopup()">
                    Cancel
                </b-button>

                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                    v-if="showProceedbtn" class="btn cancel-save-btn button-sm" @click="proceed()">
                    Proceed
                </b-button>
            </div>
        </b-modal>
        <!-- prev constraint popup -->

        <!-- alert msg popup -->
        <b-modal id="alert-msg" size="lg" ref="alertMsg" hide-footer :hide-header="showsaveandlistbtn" :title="headText"
            :visible="showPopup">
            <div class="row col-12 page-content-regular" style="padding: 2rem 1rem">
                <p>
                    <span class="page-heading-tab">
                        {{ errorMsg }}
                    </span>
                </p>
            </div>
            <div class="button-div" style="justify-content: right; width: 100%">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                    class="btn cancel-save-btn button-sm" @click="closeAlertPopup()"> OK </b-button>
            </div>
        </b-modal>
        <!-- alert msg popup -->
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import roles from "@/common/constants/roles";

export default {
    name: "ConstraintMachinesGroup",
    data() {
        return {
            errorMsg: "",
            showPopup: false,
            showsaveandlistbtn: false,
            headText: "",
            shouldMovePrev: false,
            maxGroupCount: 2,
            moveListValue: 1,
            showLoader: true,
            plantList: [],
            machineList: [],
            workCenterList1: [],
            workCenterList2: [],
            workcenterId1: 2,
            workcenterId2: 2,
            machineList1: [],
            machineList2: [],
            constraintTypeId1: 1,
            constraintTypeId2: 1,
            plantId: {},
            committedYear: [],
            constraintHeadersDtl: [],
            noOfMachinesforGrp1: 0,
            noOfMachinesforGrp2: 0,
            constraintGrp1: "",
            constraintGrp2: "",
            extMachineList1: "",
            showProceedbtn: true,
            committedYr: new Date().getFullYear(),
            workCenterList: [],
            groupList: [
                {
                    groupNo: 1,
                    workCenterSelected: 1,
                    machineList: [],
                    noOfMachinesforGrp: 0,
                    constraintGrpName: "",
                    isPrevConstraint: "N",
                    constraintTypeId: "-1"
                }
            ],
            constraintType: [
                {
                    constraintTypeId: "Primary",
                    constraintType: "Primary"
                },
                {
                    constraintTypeId: "Secondary",
                    constraintType: "Secondary"
                },
                {
                    constraintTypeId: "Tertiary",
                    constraintType: "Tertiary"
                }
            ],
            roles: roles,
            constraintHeaders: [
                {
                    key: "ConstraintListName",
                    label: "Constraint List Name",
                    sortable: false
                },
                {
                    key: "WC",
                    label: "Work Center",
                    sortable: false
                },
                {
                    key: "MCH",
                    label: "#MCH",
                    sortable: false
                },
                {
                    key: "TypeofConstraint",
                    label: "Type of Constraint",
                    sortable: false
                },
                {
                    key: "MoveDatatoNextYear",
                    label: "Move Data to Next Year",
                    sortable: false
                }
            ],
            isPrimaryAvailable: true,
            oldMachineList: []
        };
    },
    components: {
        Breadcrumb,
        Loader
    },

    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 5
        });
        this.$store.dispatch("breadcrumb", {
            title: "Constrained Machines Group - " + new Date().getFullYear(),
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        if (!(this.$oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || this.$oidc.userProfile.roles.includes(roles.PLANTPOLEADER))) {
            this.$router.push("/");
            return;
        }
        this.getPlantDetails();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData"])
    },

    methods: {
        getPlantDetails() {
            console.log("getPlantDetails");
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetailsForList();
                this.checkGroupStatus();
            });
        },
        checkGroupStatus() {
            console.log("// GETALLCONSTRAINTTYPE");
            ApiCalls.httpCallDetails(`${Api.GETGROUPSTATUS}/${this.plantId.plantId}/${this.committedYr}/${new Date().getMonth() + 1}`, "get").then((sdata) => {
                ApiCalls.httpCallDetails(`${Api.GETALLCONSTRAINTTYPE}`, "get").then((tdata) => {
                    this.constraintType = [];
                    let allCreated = 0;
                    this.maxGroupCount = sdata.data.maxGroup - sdata.data.groupCreated.length;
                    for (let index = 0; index < sdata.data.maxGroup; index++) {
                        const element = tdata.data[index];

                        if (element.typeId === 1) this.isPrimaryAvailable = !sdata.data.groupCreated.includes(element.typeId);
                        this.constraintType.push({
                            constraintTypeId: element.typeId,
                            constraintType: element.typeName,
                            alreadyExist: sdata.data.groupCreated.includes(element.typeId)
                        });
                        allCreated = sdata.data.groupCreated.includes(element.typeId) ? allCreated + 1 : allCreated;
                    }
                    if (allCreated >= sdata.data.maxGroup) {
                        this.errorMsg = "Constrained group for all types have been completed.";
                        this.showPopup = true;
                        this.showsaveandlistbtn = true;
                        this.headText = "Alert";
                    }
                    if (allCreated < sdata.data.maxGroup) {
                        this.showsaveandlistbtn = false;
                    }
                });
            });
        },
        plantChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.getWorkCenterDetailsForList();
            this.checkGroupStatus();
        },
        getWorkCenterDetailsForList() {
            ApiCalls.httpCallDetails(Api.MRWORKCENTERALL, "post", { regionId: this.plantId.regionId }).then((data) => {
                this.workCenterList = data.data;
                this.groupList.forEach((_group, index) => {
                    this.groupList[index].workCenterSelected = data.data[index].workcenterId;
                    this.getMachinesForList(index);
                });
            });
        },
        getMachinesForList(pos) {
            this.showLoader = true;
            console.log("getMachineFilter");
            let machinePayload = {
                yearOfGroup: new Date().getFullYear(),
                plantId: this.plantId.plantId,
                wcId: this.groupList[pos].workCenterSelected
            };
            console.log("machinePayload", machinePayload);
            ApiCalls.httpCallDetails(Api.GETALLOEEMACHINES, "post", machinePayload).then((data) => {
                console.log(data.data);
                this.groupList[pos].machineList = data.data.map((machine) => {
                    return {
                        machineId: machine.machineId,
                        machineName: machine.machineName,
                        machineChecked: false,
                        machineAlreadyMarked: machine.groupedStatus
                    };
                });
                // this.groupList[pos].machineList = this.commonSort(this.groupList[pos].machineList, "")
                this.groupList[pos].machineList.sort((a, b) => {
                    if (a.machineAlreadyMarked) return 1;
                    else return b.machineAlreadyMarked ? -1 : 0
                });
                this.groupList[pos].machineList.unshift({
                    machineId: 0,
                    machineName: "All",
                    machineChecked: false,
                    machineAlreadyMarked: false
                });
                this.groupList[pos].isPrevConstraint = "N";
                this.showLoader = false;
            });
        },
        onMachineCheck(event, pos, group, ml1) {

            console.log("Checked");
            console.log(event, pos, group, ml1);
            if (ml1.machineName === "All") {
                this.groupList[pos].machineList.forEach((machine) => {
                    machine.machineChecked = event;
                });
            } else {
                let checkedPos = this.groupList[pos].machineList.findIndex((wc) => wc.machineId === ml1.machineId);
                this.groupList[pos].machineList[checkedPos].machineChecked = event;
            }
            let totalMarked = 0;
            this.groupList[pos].machineList.forEach((machine) => {
                if (machine.machineChecked && machine.machineId !== 0) totalMarked += 1;
            });
            group.noOfMachinesforGrp = totalMarked;
        },
        addNewGroup() {
            console.log(this.groupList);
            this.groupList.push({
                groupNo: 2,
                workCenterSelected: this.workCenterList[0].workcenterId,
                machineList: [],
                noOfMachinesforGrp: 0,
                constraintGrpName: "",
                constraintTypeId: "Secondary",
                isPrevConstraint: "N"
            });
            this.getMachinesForList(this.groupList.length - 1);
        },
        getConstraintDtlsForNextYear() {
            this.shouldMovePrev = true;
            let payloadForNextYear = {
                plantId: this.plantId.plantId,
                yearOfGroup: new Date().getFullYear() - 1
            };
            ApiCalls.httpCallDetails(Api.GETCONSTRAINTDETAILSFORNXTYR, "post", payloadForNextYear).then((dt) => {
                this.response = dt.data;
                if (dt.data.length === 0) {
                    this.showProceedbtn = false;
                }
                this.constraintHeadersDtl = this.response.map((data) => {
                    data["isChecked"] = true;
                    return data;
                });

                console.log("Response", this.constraintHeadersDtl);
            });
        },
        prevYearCheckBox(event, index) {
            this.constraintHeadersDtl[index].isChecked = event;
        },
        proceed() {
            this.shouldMovePrev = true;
            this.showLoader = true;
            const prevGroupIds = [];
            this.constraintHeadersDtl.forEach((item) => {
                if (item.isChecked) prevGroupIds.push(item.groupId);
            });
            ApiCalls.httpCallDetails(Api.GETMACHINESBYOEECONSTRGROUPID, "post", prevGroupIds).then(async (dt) => {
                this.resp = dt.data;
                let tempGroupList = [];
                dt.data = this.commonSort(dt.data, 'typeId');
                console.log("machinePayload", dt.data);

                dt.data.sort((a, b) => {
                    if (a.typeId < b.typeId) return 1;
                    else return a.typeId === b.typeId ? 0 : -1
                });
                await dt.data.forEach((item, ind) => {
                    let machinePayload = {
                        yearOfGroup: new Date().getFullYear(),
                        plantId: this.plantId.plantId,
                        wcId: item.machines[0].wcId
                    };
                    console.log("machinePayload", machinePayload);
                    let rawMachineList = [];
                    const prevMachieList = item.machines.map((machine) => machine.machineId);
                    ApiCalls.httpCallDetails(Api.GETALLOEEMACHINES, "post", machinePayload).then((data) => {
                        console.log(data.data);

                        rawMachineList = this.rawMachineListFormatter(data, prevMachieList)

                        tempGroupList.push({
                            groupNo: ind + 1,
                            workCenterSelected: item.machines[0].wcId,
                            machineList: rawMachineList,
                            noOfMachinesforGrp: item.machines.length,
                            constraintGrpName: item.machines[0].constraintGroupName,
                            isPrevConstraint: "Y",
                            constraintTypeId: item.machines[0].contstaintType
                        });
                        tempGroupList.sort((a, b) => {
                            if (a.constraintTypeId < b.constraintTypeId) {
                                return -1;
                            }
                            else
                                return a.constraintTypeId === b.constraintTypeId ? 0 : 1;
                        });

                        this.shouldMovePrev = true;
                        document.getElementsByName("moveListValue")[0].checked = true;
                        this.showLoader = false;
                        this.groupList = tempGroupList;
                    });
                });
                this.shouldMovePrev = true;
                document.getElementsByName("moveListValue")[0].checked = true;
                this.showLoader = false;
            });

            this.$refs["myModal"].hide();
        },
        rawMachineListFormatter(data, prevMachieList) {
            let isAllChecked = 0;
            let rawMachineList = data.data.map((machine) => {
                if (prevMachieList.includes(machine.machineId)) {
                    isAllChecked++;
                    this.oldMachineList.push(machine.machineId);
                }
                return {
                    machineId: machine.machineId,
                    machineName: machine.machineName,
                    machineChecked: prevMachieList.includes(machine.machineId),
                    machineAlreadyMarked: false
                };
            });
            rawMachineList.unshift({
                machineId: 0,
                machineName: "All",
                machineChecked: isAllChecked === rawMachineList.length,
                machineAlreadyMarked: false
            });

            return rawMachineList
        },
        commonSort(arr, key) {
            arr.sort((a, b) => {
                if (a[key] < b[key]) {
                    return -1;
                }
                else
                    return a[key] === b[key] ? 0 : 1;
            });
            return arr
        },
        cancelList() {
            console.log("Inside cancelList");
            this.enableSecondList = false;
        },
        closePopup() {
            this.$refs["myModal"].hide();
            this.shouldMovePrev = false;
            document.getElementsByName("moveListValue").checked = false;
        },
        closeAlertPopup() {
            this.$store.dispatch("oeeSelectedMonth", Number(new Date().getMonth()) + 1);
            this.$store.dispatch("oeeSelectedYear", this.committedYr);
            if (this.showsaveandlistbtn) this.$router.push("/edit-constraint-machines-group");
            this.showPopup = false;
        },
        closeAlertPopup1() {
            this.$store.dispatch("oeeSelectedMonth", Number(new Date().getMonth()) + 1);
            this.$store.dispatch("oeeSelectedYear", this.committedYr);
            this.$router.push("/edit-constraint-machines-group");
            this.showPopup = false;
        },
        isPayloadValid(payLoad) {
            let valid = true;
            let totalMachines = [];
            payLoad.forEach((item, index) => {
                if (item.constraintGroupName.length < 2) {
                    valid = false;
                    this.errorMsg = "Constrained WC Name is too short";
                    this.showPopup = true;
                    this.headText = "Error";
                } else if (item.contstaintType === "" || item.contstaintType === "-1") {
                    valid = false;
                    this.errorMsg = "Constrained Type is empty";
                    this.showPopup = true;
                    this.headText = "Error";
                } else if (item.totalMachines <= 0) {
                    valid = false;
                    this.errorMsg = "Machine is not selected. Please select at least one machine in Group.";
                    this.showPopup = true;
                    this.headText = "Error";
                } else {
                    if (item.isPreviousConstraint === "") {
                        valid = false;
                        this.errorMsg = "Please select previous constraint option.";
                        this.showPopup = true;
                        this.headText = "Error";
                    }
                }
                totalMachines = totalMachines.concat(item.machineIds);
            });
            valid = this.complexCheck(valid, totalMachines, payLoad)
            return valid;
        },
        complexCheck(valid, totalMachines, payLoad) {
            let uniqueList = Array.from(new Set(totalMachines));
            if (valid) {
                if (uniqueList.length !== totalMachines.length) {
                    valid = false;
                    this.errorMsg = "A machine can be tag to one group only.";
                    this.showPopup = true;
                    this.headText = "Error";
                } else if (
                    (payLoad.length > 1 && payLoad[0].contstaintType === payLoad[1].contstaintType) ||
                    (payLoad.length > 2 && (payLoad[0].contstaintType === payLoad[1].contstaintType) === payLoad[2].contstaintType)
                ) {
                    valid = false;
                    this.errorMsg = "Constraint type is same on both group";
                    this.showPopup = true;
                    this.headText = "Error";
                }
            }
            return valid;
        },
        saveManualData() {
            this.showPopup = false;
            this.showLoader = true;
            const reqBody = this.groupList.map((groupItem, index) => {
                const machineList = [];
                groupItem.machineList.forEach((machine) => {
                    if (machine.machineChecked && machine.machineId !== 0) machineList.push(machine.machineId);
                });
                return {
                    constraintGroupName: groupItem.constraintGrpName,
                    contstaintType: groupItem.constraintTypeId,
                    yearOfGroup: this.committedYr,
                    wcId: groupItem.workCenterSelected,
                    totalMachines: groupItem.noOfMachinesforGrp,
                    lastUpdatedBy: this.$oidc.userProfile.email,
                    plantId: this.plantId.plantId,
                    isPreviousConstraint: groupItem.isPrevConstraint === "Y" ? true : false,
                    machineIds: machineList
                };
            });
            console.log(reqBody.length === 1, reqBody[0].contstaintType !== 1, this.isPrimaryAvailable);

            if (!(reqBody.length === 1 && reqBody[0].contstaintType !== 1 && this.isPrimaryAvailable)) {
                this.saveAPICall(reqBody)
            } else {
                this.showLoader = false;
                this.errorMsg = "Please create primary constraint first.";
                this.showPopup = true;
                this.headText = "Error";
            }
        },
        saveAPICall(reqBody) {
            if (this.isPayloadValid(reqBody)) {
                ApiCalls.httpCallDetails(Api.SAVEMROEECONSTRAINTGROUP, "post", reqBody).then((data) => {
                    this.showLoader = false;
                    console.log(data);
                    if (data.status) this.$router.push("/machine-assessment");
                    else {
                        this.errorMsg = data.message.trim();
                        this.showPopup = true;
                        this.headText = "Error";
                    }
                });
            } else {
                this.showLoader = false;
                console.log("Something wrong.");
            }
        }
    }
};
</script>

<style scoped>
.individual-checkbox {
    width: 33%;
}

.constraint-heading-lbl {
    color: #234372;
    font: 1.166rem "BarlowM", sans-serif !important;
    padding: 0.25rem 1.2rem;
}

.plant-card-constraint {
    background: #fffce3;
    width: 22rem;
    height: 4.5rem;
    text-align: center;
    color: #414141;
    padding: 5px;
    border-radius: 7px;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

/* ------- */
.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.stacked-elements {
    padding: 2rem !important;
    display: flex;
    flex-direction: column;
}

.no-padding {
    padding: 0;
}

.border-box {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    opacity: 1;
}

.no-data-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
}

.scroll {
    height: 25rem;
}

.alter-of-border-box {
    display: flex;
    height: 25rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.button-div {
    width: 22rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chk-container {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 0.7rem;
}

/* ------- */

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-status-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.machine-action-tracker {
    margin-top: 2rem;
    width: 100%;
}

#machine-action-tracker th.table-success {
    background-color: #f4f6fd;
}

#machine-action-tracker th:first-child,
#machine-action-tracker th:nth-child(2) {
    background-color: white !important;
    font-weight: medium;
}

#machine-action-tracker tr:nth-child(3n-2) td:first-child {
    border-bottom: 1px solid #eae6e6 !important;
}

#machine-action-tracker tr:nth-child(3n) td {
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-action-tracker-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-action-table {
    max-height: 20rem !important;
}

.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

/* .machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
} */

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
}

#machine-action-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th:first-child {
    width: 15%;
}


.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #f5f5ea !important;
}

.machine-action-table tbody tr:nth-child(odd) {
    background-color: #fafaf0 !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}


.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}


@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
